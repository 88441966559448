import React from 'react';
import { NavLink } from 'react-router-dom';

class F_Start extends React.Component {
    render() {
        return (
            <>
                <div className="section-full welcome-section-outer">
                    <div className="welcome-section-top bg-white p-t80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 px-4">
                                    <div className="img-colarge">
                                        <div className="colarge-1"><img src={require('./../../images/colarge/IMG_5084_min.png').default} alt="" className="slide-righ" /></div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center col-lg-6 col-md-12 px-4">
                                    <div className="welcom-to-section">
                                        {/* TITLE START*/}
                                        <div className="left wt-small-separator-outer">
                                            <div className="wt-small-separator site-text-primary">
                                                <div className="sep-leaf-left" />
                                                <div>Van Rietschoten</div>
                                                <div className="sep-leaf-right" />
                                            </div>
                                        </div>
                                        {/* TITLE END*/}
                                        <h2>Wie zijn wij?</h2>
                                        <p>Als leverancier van specialistisch en op-maat constructiewerk is Van Rietshoten een begrip in de metaalindustrie en isolatiebranche. U kunt bij ons terecht voor het knippen en zetten van alle soorten staalplaten en non-ferro materialen. Ook leveren wij prefab knip- en zetwerk voor de fabricage van kleine en grote toepassingen in de industrie, de utiliteitsbouw en carrosseriebouw.</p>
                                        <h2>Snel staalplaten nodig?</h2>
                                        <p>Wij beschikken over een geavanceerd machinepark met een grote capaciteit. Dankzij onze up-to-date voorraad van materialen zijn we u snel van dienst, meestal binnen 24 uur. Vanuit onze vestiging in Vierpolders is de industrie in Europoort/Botlek uitstekend bereikbaar.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default F_Start;