import React from 'react';
import Navigation from '../Parts/Navigation_NonIndex';
import { NavLink } from 'react-router-dom';

var bnr = require('./../../images/background/bg-7.jpg');
var bnr2 = require('./../../images/background/bg-map2.png');


class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            logo: require('./../../images/logo-small.png'),
            isSearchActive: false,
            isQuoteActive: false,
            success: false,
            formData: {
                name: '',
                email: '',
                phone: '',
                subject: '',
                message: ''
            }
        };
    }

    state = { isSearchActive: false, isQuoteActive: false };

    handleSearchToggle = () => {
        this.setState({ isSearchActive: !this.state.isSearchActive });
    };

    handleQuoteToggle = () => {
        this.setState({ isQuoteActive: !this.state.isQuoteActive });
    };

    componentDidMount() {

        const handleScroll = () => {
            const offset = window.scrollY;

            const stickyheader = document.querySelector('.sticky-header ');

            if (offset >= 100) {
                stickyheader.classList.add('is-fixed');
                stickyheader.classList.add('color-fill');

            } else {
                stickyheader.classList.remove('is-fixed');
                stickyheader.classList.remove('color-fill');
            }
        }

        window.addEventListener('scroll', handleScroll);

        window.updateTopMostParent = (logopath) => {
            this.setState({ logo: logopath });
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
            formData: {
                ...prevState.formData,
                [name]: value
            }
        }));
    };

    handleSubmit = async (event) => {
        event.preventDefault();

        console.log(this.state.formData);
        this.setState({ success: true });

        setTimeout(() => {
            this.setState({ success: false });
        }, 4500);

        try {
            // const response = await fetch('./forms/quotation.php', {
            const response = await fetch('https://360fabriek.nl/rietschoten3/forms/contact_test_x.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(this.state.formData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Success:', data);

            // Add any additional logic or state changes after successful form submission
        } catch (error) {
            console.error('Error:', error);
        }
    };

    render() {
        const isSearchActive = this.state.isSearchActive;
        const isQuoteActive = this.state.isQuoteActive;

        return (
            <>
                <header id="top" className="site-header header-style-1 mobile-sider-drawer-menu">
                    {/* SITE Search */}
                    <div id="search-toggle-block" style={{ display: isSearchActive ? "block" : "none" }}>
                        <div id="search">
                            <form role="search" id="searchform" action="/search" method="get" className="radius-xl">
                                <div className="input-group">
                                    <input defaultValue="" className="form-control" name="q" type="search" placeholder="Type to search" />
                                    <span className="input-group-append"><button type="button" className="search-btn"><i className="fa fa-search" /></button></span>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="container header-middle clearfix">
                        <div className="logo-header">
                            <div className="logo-header-inner logo-header-one">
                                <NavLink to={"./"}>
                                    <img src={this.state.logo.default} alt="Logo" />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="sticky-header main-bar-wraper  navbar-expand-lg">
                        <div className="main-bar">
                            <div className="container clearfix">
                                {/* NAV Toggle Button */}
                                <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button" className="navbar-toggler collapsed">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar icon-bar-first" />
                                    <span className="icon-bar icon-bar-two" />
                                    <span className="icon-bar icon-bar-three" />
                                </button>
                                {/* MAIN Vav */}
                                <Navigation />

                                <div className="header-nav-request">
                                    <NavLink to={"#"} className="contact-slide-show" onClick={this.handleQuoteToggle}>Neem Contact Op <i className="fa fa-angle-right" /></NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                <div className="contact-slide-hide bg-cover bg-no-repeat" style={{ backgroundImage: 'url(' + bnr.default + ')', right: isQuoteActive ? '0%' : '-100%' }}>
                    <div className="contact-nav">
                        <NavLink to={"#"} className="contact_close" onClick={this.handleQuoteToggle}>×</NavLink>
                        {this.state.success && (
                            <div className="success-message">
                                <p>Bericht verzonden!</p>
                            </div>
                        )}
                        <div className="contact-nav-form">
                            <div className="contact-nav-info bg-white p-a30 bg-center bg-no-repeat" style={{ backgroundImage: "url(" + bnr2.default + ")" }}>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4">
                                        <div className="contact-nav-media-section">
                                            <div className="contact-nav-media">
                                                <img src={require('./../../images/new-index/colleg-pic3.png').default} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-8">
                                        <form className="cons-contact-form" onSubmit={this.handleSubmit}>
                                            <div className="m-b30">
                                                {/* TITLE START */}
                                                <h2 className="m-b30">Neem Contact op</h2>
                                                {/* TITLE END */}
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <input name="name" type="text" required className="form-control" placeholder="Naam" value={this.state.formData.name} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <input name="email" type="text" required className="form-control" placeholder="E-mail" value={this.state.formData.email} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <input name="phone" type="text" className="form-control" required placeholder="Telefoonnummer" value={this.state.formData.phone} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-sm-6">
                                                        <div className="form-group">
                                                            <input name="subject" type="text" className="form-control" required placeholder="Onderwerp" value={this.state.formData.subject} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <textarea name="message" className="form-control" rows={4} required placeholder="Bericht" defaultValue={""} value={this.state.formData.message} onChange={this.handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button type="submit" className="site-button site-btn-effect">Versturen</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.success && (
                                                <div className="success-message">
                                                    <p>Your message has been sent successfully!</p>
                                                </div>
                                            )}

                                        </form>
                                        <div className="contact-nav-inner text-black">
                                            {/* TITLE START */}
                                            <h2 className="m-b30">Contactgegevens</h2>
                                            {/* TITLE END */}
                                            <div className="row">
                                                <div className="col-lg-4 col-md-12">
                                                    <div className="wt-icon-box-wraper left icon-shake-outer">
                                                        <div className="icon-content">
                                                            <h4 className="m-t0">Telefoon</h4>
                                                            <p>0181 - 61 75 31</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-12">
                                                    <div className="wt-icon-box-wraper left icon-shake-outer">
                                                        <div className="icon-content">
                                                            <h4 className="m-t0">E-mail</h4>
                                                            <a href="mailto:info@rietschoten.nl">info@rietschoten.nl</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-12">
                                                    <div className="wt-icon-box-wraper left icon-shake-outer">
                                                        <div className="icon-content">
                                                            <h4 className="m-t0">Adres</h4>
                                                            <p>Seggeland-Noord 13, 3237 MG Vierpolders</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </>
        );
    };
};

export default Header;

