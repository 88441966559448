import React from 'react';
import Header from '../Parts/Header';
import Footer3 from '../Parts/Footer3';
import Banner from './../Segments/Banner';
import P_Mid from './../Segments/P_Mid';
import P_List from './../Segments/P_List';
import P_List_Mirrored from './../Segments/P_List_Mirrored';
import P_Start from './../Segments/P_Start';
import Projects12 from './../Segments/Projects12';
import Statistics12 from './../Segments/Statistics12';
import Video2 from './../Segments/Video2';
import SelfIntro1 from './../Segments/SelfIntro1';
import ClientsLogo1 from './../Segments/ClientsLogo1';

var bnrimg = require('./../../images/background/bg-map2.png');
var full = require('./../../images/background/IMG_5088_min.png');

class Plaatmateriaal extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner extraclass="flensbeschermersbanner" title="Plaatmateriaal" pagename="Plaatmateriaal" bgimage={bnrimg.default} />
                    <P_Start />
                    <P_Mid />
                    <P_List />
                    <P_List_Mirrored />
                    <div className="section-full welcome-section-outer p-mid">
                        <div className="welcome-section-top bg-white p-t80 p-b40">
                            <div className="container">
                                <div className="row d-flex justify-content-center align-items-center p-b80">
                                    <h2 className="longtitle">
                                        <span><b>Nieuwsgierig?</b></span>
                                    </h2>
                                    <p>Wilt u weten wat wij (nog meer) voor u kunnen betekenen? Wij denken graag met u mee. Neem gerust contact met ons op.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer3 />
            </>
        );
    };
};

export default Plaatmateriaal;