import React from 'react';
import { NavLink } from 'react-router-dom';

class F_Mid extends React.Component {
    render() {
        return (
            <>
                <div className="section-full welcome-section-outer p-mid">
                    <div className="welcome-section-top bg-white p-t80 p-b40">
                        <div className="container">
                            <div className="row d-flex justify-content-center align-items-center p-b80">
                                <h2 className="longtitle">
                                    <span>Staalplaten knippen en zetten?</span>
                                    <span><b>Van Rietschoten:</b> knip- en zetwerk van plaatmateriaal.</span>
                                </h2>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-12 px-4">
                                    <div className="img-colarge">
                                        <div className="colarge-1 mirr-cor"><img src={require('./../../images/colarge/IMG_5108_min.png').default} alt="" className="slide-righ" /></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 px-4">
                                    <div className="img-colarge">
                                        <div className="colarge-1 "><img src={require('./../../images/colarge/IMG_5103_min.png').default} alt="" className="slide-righ" /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default F_Mid;