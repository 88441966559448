import React, { Suspense, useState, useEffect } from 'react';
import GoogleMaps from "simple-react-google-maps"
import Header from './../Parts/Header';
import Footer3 from './../Parts/Footer3';
import Banner from './../Segments/Banner';

var bnrimg = require('./../../images/background/bg-map2.png');
var bnr1 = require('./../../images/main-slider/slider1/DSC01719-edit.jpg');
var bgimg = require('./../../images/background/bg-map2.png');

function ContactUs({ product }) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });

    useEffect(() => {
        if (product) {
            setFormData(prevState => ({ ...prevState, product_data: product.category + " - " + product.title }));
        }
    }, [product]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log(formData);

        try {
            // const response = await fetch('./forms/quotation.php', {
            const response = await fetch('https://360fabriek.nl/rietschoten3/forms/contact.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Success:', data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <>
            <Header />
            <div id="top" className="page-content">
                {/* INNER PAGE BANNER */}
                <Banner extraclass="contactbanner" title="Contact" pagename="Contact" bgimage={bnrimg.default} />
                {/* INNER PAGE BANNER END */}
                {/* SECTION CONTENTG START */}
                {/* CONTACT FORM */}
                <div className="section-full  p-t80 p-b50 bg-cover bg-img-fix" style={{ backgroundImage: "url(" + bnr1.default + ")" }}>
                    <div className="section-content">
                        <div className="container">
                            <div className="contact-one">
                                {/* CONTACT FORM*/}
                                <div className="row  d-flex justify-content-center flex-wrap">
                                    <div className="col-lg-6 col-md-6 m-b30">
                                        <form className="cons-contact-form" onSubmit={handleSubmit}>
                                            {/* TITLE START */}
                                            <div className="section-head left wt-small-separator-outer">
                                                <div className="wt-small-separator site-text-primary">
                                                    <div className="sep-leaf-left" />
                                                    <div>Contact</div>
                                                    <div className="sep-leaf-right" />
                                                </div>
                                                <h2>Neem Contact Op</h2>
                                            </div>
                                            {/* TITLE END */}
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input name="name" type="text" required className="form-control" placeholder="Naam" value={formData.name} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input name="email" type="text" className="form-control" required placeholder="E-mail" value={formData.email} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input name="phone" type="text" className="form-control" required placeholder="Telefoonnummer" value={formData.phone} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input name="subject" type="text" className="form-control" required placeholder="Onderwerp" value={formData.subject} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <textarea name="message" className="form-control" rows={4} required placeholder="Bericht" defaultValue={""} value={formData.message} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <button type="submit" className="site-button site-btn-effect">Versturen</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-lg-6 col-md-6 m-b30">
                                        <div className="contact-info">
                                            <div className="contact-info-inner">
                                                {/* TITLE START*/}
                                                <div className="section-head left wt-small-separator-outer">
                                                    <div className="wt-small-separator site-text-primary">
                                                        <div className="sep-leaf-left" />
                                                        <div>Contactgegevens</div>
                                                        <div className="sep-leaf-right" />
                                                    </div>
                                                    <h2>Waar Je Ons Kunt Vinden</h2>
                                                </div>
                                                {/* TITLE END*/}
                                                <div className="contact-info-section" style={{ backgroundImage: "url(" + bgimg.default + ")" }}>
                                                    <div className="wt-icon-box-wraper left m-b30">
                                                        <div className="icon-content">
                                                            <h3 className="m-t0 site-text-primary">Telefoonnummer</h3>
                                                            <p>0181 - 61 75 31</p>
                                                        </div>
                                                    </div>
                                                    <div className="wt-icon-box-wraper left m-b30">
                                                        <div className="icon-content">
                                                            <h3 className="m-t0 site-text-primary">E-mail</h3>
                                                            <a href="mailto:info@rietschoten.nl" className="white-url">info@rietschoten.nl</a>
                                                        </div>
                                                    </div>
                                                    <div className="wt-icon-box-wraper left m-b30">
                                                        <div className="icon-content">
                                                            <h3 className="m-t0 site-text-primary">Adres</h3>
                                                            <p>Seggeland-Noord 13, 3237 MG Vierpolders</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* GOOGLE MAP */}
                <div className="section-full bg-white p-tb80">
                    <div className="section-content">
                        <div className="container">
                            <div className="gmap-outline">
                                <GoogleMaps
                                    apiKey={"AIzaSyAfY1DRbspf6E3jYUso-PeI_tdfRXA59i0"}
                                    style={{ height: "400px", width: "100%" }}
                                    zoom={12}
                                    center={{ lat: 51.8954149341652, lng: 4.191349911499613 }}
                                    markers={{ lat: 51.8954149341652, lng: 4.191349911499613 }} //optional
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer3 />

        </>
    );
};

export default ContactUs;