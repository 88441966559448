import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home1 from './Pages/Home1';
import Error403 from './Pages/Error403';
import Error404 from './Pages/Error404';
import Error405 from './Pages/Error405';
import ContactUs from './Pages/ContactUs';
import Flensbeschermers from './Pages/Flensbeschermers';
import Plaatmateriaal from './Pages/Plaatmateriaal';

class Components extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <div className="page-wraper">
                        <Switch>
                            <Route path='/' exact component={Home1} />

                            <Route path='/error403' component={Error403} />
                            <Route path='/error404' component={Error404} />
                            <Route path='/error405' component={Error405} />

                            <Route path='/contactus' component={ContactUs} />
                            <Route path='/flensbeschermers' component={Flensbeschermers} />
                            <Route path='/plaatmateriaal' component={Plaatmateriaal} />
                            
                            <Route component={Error} />
                        </Switch>
                </div>
            </BrowserRouter>
        );
    };
};

export default Components;