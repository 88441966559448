import React from 'react';

const productFormMapping = {
    'Spaakring_Één keer thermisch ontkoppeld': React.lazy(() => import('./forms/Spaakring_1x')),
    'Spaakring_Drie keer thermisch ontkoppeld (verend)': React.lazy(() => import('./forms/Spaakring_3x_verend')),
    'Spaakring_Twee keer thermisch ontkoppeld (verend)': React.lazy(() => import('./forms/Spaakring_2x_verend')),
    'Spaakring_Twee keer thermisch ontkoppeld (verend, met U-pootje)': React.lazy(() => import('./forms/Spaakring_2x_Upoot')),
    'Isolatiesupport_Één keer thermisch ontkoppeld': React.lazy(() => import('./forms/Isolatiesupport_1x')),
    'Isolatiesupport_Één keer thermisch ontkoppeld (met U-pootje)': React.lazy(() => import('./forms/Isolatiesupport_1x_Upoot')),
    'Isolatiesupport_Omegaverend (met U-pootje)': React.lazy(() => import('./forms/Isolatiesupport_Omegaverend_Upoot')),
    'Isolatiesupport_Twee keer thermisch ontkoppeld': React.lazy(() => import('./forms/Isolatiesupport_2x')),
    'U-profiel_Één keer thermisch ontkoppeld': React.lazy(() => import('./forms/Uprofiel_1x')),
    'U-profiel_Één keer thermisch ontkoppeld (met U-pootje)': React.lazy(() => import('./forms/Uprofiel_1x_Upoot')),
    'U-profiel_Twee keer thermisch ontkoppeld': React.lazy(() => import('./forms/Uprofiel_2x')),
    'U-profiel_Drie keer thermisch ontkoppeld (omegaverende poot)': React.lazy(() => import('./forms/Uprofiel_2x_Omegaverend')),
    'U-profiel_Twee keer thermisch ontkoppeld (omegaverende poot)': React.lazy(() => import('./forms/Uprofiel_Omegaverend_Upoot')),
    'U-profiel_Met omegaverende poot': React.lazy(() => import('./forms/Uprofiel_Omegaverend')),
    'Kooiring_Twee keer thermisch ontkoppeld': React.lazy(() => import('./forms/Kooiring')),
    'Kooiring_Drie keer thermisch ontkoppeld (verend)': React.lazy(() => import('./forms/Kooiring_3x_verend')),
    // ... other mappings ...
};

const productPdfMapping = {
    'Spaakring_Één keer thermisch ontkoppeld': 'Spaakring_1x.pdf',
    'Spaakring_Drie keer thermisch ontkoppeld (verend)': 'Spaakring_3x_verend.pdf',
    'Spaakring_Twee keer thermisch ontkoppeld (verend)': 'Spaakring_2x_verend.pdf',
    'Spaakring_Twee keer thermisch ontkoppeld (verend, met U-pootje)': 'Spaakring_2x_Upoot.pdf',
    'Isolatiesupport_Één keer thermisch ontkoppeld': 'Isolatiesupport_1x.pdf',
    'Isolatiesupport_Één keer thermisch ontkoppeld (met U-pootje)': 'Isolatiesupport_1x_Upoot.pdf',
    'Isolatiesupport_Omegaverend (met U-pootje)': 'Isolatiesupport_Omegaverend_Upoot.pdf',
    'Isolatiesupport_Twee keer thermisch ontkoppeld': 'Isolatiesupport_2x.pdf',
    'U-profiel_Één keer thermisch ontkoppeld': 'Uprofiel_1x.pdf',
    'U-profiel_Één keer thermisch ontkoppeld (met U-pootje)': 'Uprofiel_1x_Upoot.pdf',
    'U-profiel_Twee keer thermisch ontkoppeld': 'Uprofiel_2x.pdf',
    'U-profiel_Drie keer thermisch ontkoppeld (omegaverende poot)': 'Uprofiel_3x_Omegaverend.pdf',
    'U-profiel_Twee keer thermisch ontkoppeld (omegaverende poot)': 'Uprofiel_Omegaverend_Upoot.pdf',
    'Kooiring_Twee keer thermisch ontkoppeld': 'Kooiring.pdf',
    'Kooiring_Drie keer thermisch ontkoppeld (verend)': 'Kooiring_3x_verend.pdf',
    'U-profiel_Met omegaverende poot': 'Uprofiel_Omegaverend.pdf'
    // ... add other mappings ...
};

export { productFormMapping, productPdfMapping };