import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import OrderlistSegment from './OrderListSegment';

const filters = [
    { label: "Brackets", filter: ".bracket" },
    { label: "U-profielen", filter: ".u_profiel" },
    { label: "Kooi- & Spaakringen", filter: ".spaakring" },
    { label: "Isolatiesupport", filter: ".isolatiesupport" },
    { label: "Stormband", filter: ".stormband" }
];

const projects = [
    {
        image: require('./../../images/products/bracket_omegaveer_3x_tbs_ketel_isolatie_1.png'),
        orderimg: require('./../../images/products/bracket_omegaveer_3x_tbs_ketel_isolatie.png'),
        title: 'Drie keer thermisch ontkoppeld (met omegabeugel)',
        category: 'Bracket',
        filter: 'bracket'
    },
    {
        image: require('./../../images/products/bracket_tbv_ketelisolatie.png'),
        title: 'TBV, ketelisolatie',
        category: 'Bracket',
        filter: 'bracket'
    },
    {
        image: require('./../../images/products/bracket_tbv_ketelisolatie_verend.png'),
        title: 'TBV, ketelisolatie (met omegabeugel)',
        category: 'Bracket',
        filter: 'bracket'
    },
    {
        image: require('./../../images/products/0005.png'),
        title: 'Één keer thermisch ontkoppeld',
        category: 'Isolatiesupport',
        filter: 'isolatiesupport'
    },
    {
        image: require('./../../images/products/0018.png'),
        title: 'Één keer thermisch ontkoppeld (met U-pootje)',
        category: 'Isolatiesupport',
        filter: 'isolatiesupport'
    },
    {
        image: require('./../../images/products/0027.png'),
        title: 'Omegaverend (met U-pootje)',
        category: 'Isolatiesupport',
        filter: 'isolatiesupport'
    },
    {
        image: require('./../../images/products/0008_1.png'),
        orderimg: require('./../../images/products/0008.png'),
        title: 'Twee keer thermisch ontkoppeld',
        category: 'Isolatiesupport',
        filter: 'isolatiesupport'
    },
    {
        image: require('./../../images/products/kooiring.png'),
        title: 'Twee keer thermisch ontkoppeld',
        category: 'Kooiring',
        filter: 'spaakring'
    },
    {
        image: require('./../../images/products/spaakring_1x.png'),
        title: 'Één keer thermisch ontkoppeld',
        category: 'Spaakring',
        filter: 'spaakring'
    },
    {
        image: require('./../../images/products/spaakring_verend_2x.png'),
        title: 'Twee keer thermisch ontkoppeld (verend)',
        category: 'Spaakring',
        filter: 'spaakring'
    },
    {
        image: require('./../../images/products/spaakring_verend_2x_upoot.png'),
        title: 'Twee keer thermisch ontkoppeld (verend, met U-pootje)',
        category: 'Spaakring',
        filter: 'spaakring'
    },
    {
        image: require('./../../images/products/spaakring_verend_3x.png'),
        orderimg: require('./../../images/products/spaakring_verend_3x_2.png'),
        title: 'Drie keer thermisch ontkoppeld (verend)',
        category: 'Spaakring',
        filter: 'spaakring'
    },
    {
        image: require('./../../images/products/kooiring_verend_3x.png'),
        title: 'Drie keer thermisch ontkoppeld (verend)',
        category: 'Kooiring',
        filter: 'spaakring'
    },
    {
        image: require('./../../images/products/0006.png'),
        orderimg: require('./../../images/products/0006_2.png'),
        title: 'Één keer thermisch ontkoppeld',
        category: 'U-profiel',
        filter: 'u_profiel'
    },
    {
        image: require('./../../images/products/0019.png'),
        orderimg: require('./../../images/products/0019_2.png'),
        title: 'Één keer thermisch ontkoppeld (met U-pootje)',
        category: 'U-profiel',
        filter: 'u_profiel'
    },
    {
        image: require('./../../images/products/0009_1.png'),
        orderimg: require('./../../images/products/0009.png'),
        title: 'Twee keer thermisch ontkoppeld',
        category: 'U-profiel',
        filter: 'u_profiel'
    },
    {
        image: require('./../../images/products/0028.png'),
        orderimg: require('./../../images/products/0028_2.png'),
        title: 'Twee keer thermisch ontkoppeld (omegaverende poot)',
        category: 'U-profiel',
        filter: 'u_profiel'
    },
    {
        image: require('./../../images/products/0025.png'),
        orderimg: require('./../../images/products/0025_2.png'),
        title: 'Drie keer thermisch ontkoppeld (omegaverende poot)',
        category: 'U-profiel',
        filter: 'u_profiel'
    },
    {
        image: require('./../../images/products/0022.png'),
        orderimg: require('./../../images/products/0022_2.png'),
        title: 'Met omegaverende poot',
        category: 'U-profiel',
        filter: 'u_profiel'
    },
    {
        image: require('./../../images/products/rvs_stormband.png'),
        title: 'RVS Stormband',
        category: 'Stormband',
        filter: 'stormband'
    }
];

class ProductsSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logo: require('./../../images/logo-small.png'),
            isOrderlistActive: false,
            activeProduct: null
        };

        this.handleOrderlistToggle = this.handleOrderlistToggle.bind(this);
    }

    handleProductClick = (product, e) => {
        e.stopPropagation();
        this.setState({
            isOrderlistActive: true,
            activeProduct: product
        }, () => {
            // Disable scrolling on the body when the OrderList is active
            document.body.style.overflow = 'hidden';
        });
    }

    handleOrderlistToggle() {
        this.setState((prevState) => ({
            isOrderlistActive: !prevState.isOrderlistActive
        }), () => {
            // Toggle the body's overflow property based on the new state
            document.body.style.overflow = this.state.isOrderlistActive ? 'hidden' : 'auto';
        });
    }

    render() {

        const isQuoteActive = this.state.isQuoteActive;
        const isOrderlistActive = this.state.isOrderlistActive;

        return (
            <>
                <div className="section-full p-t80 p-b80 p-lr30 project-style-new site-bg-gray" id="products">
                    <div className="container">
                        <div className="wt-separator-two-part">
                            <div className="row wt-separator-two-part-row">
                                <div className="col-xl-6 col-lg-12 col-md-12 wt-separator-two-part-left">
                                    {/* TITLE START*/}
                                    <div className="section-head left wt-small-separator-outer">
                                        <div className="wt-small-separator site-text-primary">
                                            <div className="sep-leaf-left" />
                                            <div>Bekijk ons aanbod</div>
                                            <div className="sep-leaf-right" />
                                        </div>
                                        <h2>Hier een aantal van onze producten</h2>
                                    </div>
                                    {/* TITLE END*/}
                                </div>
                                <div className="col-xl-6 col-lg-12 col-md-12 wt-separator-two-part-right text-right">
                                    <ul className="btn-filter-wrap">
                                        <li className="btn-filter btn-active" data-filter="*">Geen filter</li>
                                        {filters.map((item, index) => (
                                            <li className="btn-filter" key={index} data-filter={item.filter}>{item.label}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* IMAGE CAROUSEL START */}
                    <div className="section-content">
                        <div className="owl-carousel owl-carousel-filter  owl-btn-vertical-center" onClick={(e) => { const itemDiv = e.target.closest(".item"); if (itemDiv && itemDiv.dataset.product) { const product = JSON.parse(itemDiv.dataset.product); this.handleProductClick(product, e); } }}>
                            {projects.map((item, index) => (
                                <div key={index} className={`${item.filter} item`} data-product={JSON.stringify(item)} onClick={(e) => this.handleProductClick(item, e)}>
                                    <div className="wt-img-effect project-new-1">
                                        <img src={item.image.default} alt="" />
                                        <div className="project-new-content">
                                            <span className="project-new-category">{item.category}</span>
                                            <h3 className="wt-title">{item.title}</h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col d-flex justify-content-center orderlist-btn-container">
                    </div>
                    <hr />
                    <h3 className="text-center p-t80">Staat uw product er niet bij?<br />Neem gerust contact met ons op voor een passende oplossing!</h3>
                    <div className="d-flex justify-content-center p-t20"><Link className="site-button-secondry site-btn-effect" to="/ContactUs">Contact</Link></div>
                </div>

                {/* Start Orderlist Segment */}
                <OrderlistSegment
                    isOrderlistActive={this.state.isOrderlistActive}
                    handleOrderlistToggle={this.handleOrderlistToggle}
                    product={this.state.activeProduct}
                />
                {/* End Orderlist Segment */}
            </>
        );
    }
};

export default ProductsSection;