import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const testimonials = [
    {
        image: require("./../../images/testimonials/pic1.png"),
        reviewername: "John de Vreugd",
        position: "Altisol",
        review: "Ik ben gevraagd een review te schrijven en die zou heel kort kunnen zijn; ik heb niets te klagen! Sterker nog, het is vaak al eerder klaar dan ik verwacht, en dat is in deze tijden al een geschenk. Prettige leverancier om zaken mee te doen, aan de prijs, maar daar krijg je wel goede spullen voor terug en geen gezeur."
    },
    {
        image: require("./../../images/testimonials/pic2.png"),
        reviewername: "Johan Belder",
        position: "WIKO Isolatietechniek en Steigerbouw",
        review: "Mijn eerste kennismaking met Leo van Rietschoten gaat zo'n 43 jaar terug, ik was toen een broekie van 17 jaar en zat in de leerschool van de firma Gresel. Leo had een romney loods op het terrein van de firma Gresel. Eerst was het spuitisolatie aanbrengen en divers ander montagewerk uitvoeren. Langzaam maar zeker ging Van Rietschoten onderconstructie vervaardigen voor ketels en filters, wat uitgebreid werd naar leidingwerk. Ik was midden twintig toen ik mijn eerste bestellingen bij Van Rietschoten plaatste, en nu inmiddels 60 jaar bestel ik nog steeds de benodigde onderconstructie bij Van Rietschoten. Kwalitatief, kwantitatief en prijstechnisch is Van Rietschoten een prima leverancier waar je van op aan kan."
    }
]

class Testimonials7 extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: false,
            nav: true,
            dots: false,
            margin: 30,
            navText: ['<i class="fa fa-long-arrow-left"></i>', '<i class="fa fa-long-arrow-right"></i>'],
            responsive: {
                0: {
                    items: 1,
                },
                1400: {
                    items: 1
                }
            }
        };
        return (
            <>
                <div className="section-full  p-t80 p-b50 testimonial-4-outer bg-gray-light">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-md-12 m-b30 d-flex align-items-center">
                                <div className="testimonial-4-left-section">
                                    {/* TITLE START*/}
                                    <div className="left wt-small-separator-outer">
                                        <div className="wt-small-separator site-text-primary">
                                            <div className="sep-leaf-left" />
                                            <div>Hoor het van onze klanten</div>
                                            <div className="sep-leaf-right" />
                                        </div>
                                        <h2>Wij zijn trots op ons werk</h2>
                                        <a href="#productsr" className="site-button site-btn-effect">Bestel Nu</a>
                                    </div>
                                    {/* TITLE END*/}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 m-b30">
                                <div className="testimonial-4-content-outer">
                                    <OwlCarousel className="testimonial-4-content owl-carousel  owl-btn-vertical-center long-arrow-next-prev" {...options}>
                                        {testimonials.map((item, index) => (
                                            <div key={index} className="item">
                                                <div className="testimonial-4 bg-white">
                                                    <div className="testimonial-content">
                                                        <div className="testimonial-pic-block">
                                                            <div className="testimonial-pic">
                                                                <img src={item.image.default} alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="testimonial-text">
                                                            <i className="fa fa-quote-left" />
                                                            <p>{item.review}</p>
                                                        </div>
                                                        <div className="testimonial-detail clearfix">
                                                            <div className="testimonial-info">
                                                                <span className="testimonial-name  title-style-2 site-text-secondry">{item.reviewername}</span>
                                                                <span className="testimonial-position title-style-2 site-text-primary">{item.position}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Testimonials7;