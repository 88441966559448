import React from 'react';
import { NavLink } from 'react-router-dom';

class F_Start_Mirrored extends React.Component {
    render() {
        return (
            <>
                <div className="section-full welcome-section-outer p-mir">
                    <div className="welcome-section-top bg-white p-tb80">
                        <div className="container">
                            <div className="row">
                                <div className="d-flex align-items-center col-lg-6 col-md-12 px-4">
                                    <div className="welcom-to-section">
                                        <h2 className="stack"><span>Safan knipschaar,</span><span>maximale lengte 3000 mm</span></h2>
                                        <ul className="site-list-style-one">
                                            <li>Maximale plaatdikte</li>
                                            <li>Staal tot 6 mm</li>
                                            <li>RVS tot 5 mm</li>
                                            <li>Aluminium tot 8mm</li>
                                            <li>Van 1 stuk- tot serieproducties</li>
                                        </ul>
                                        <h2 className="stack"><span>Safan zetbank,</span><span>maximale lengte 3000 mm</span></h2>
                                        <ul className="site-list-style-one">
                                            <li>Capaciteit 100 ton</li>
                                            <li>Maximale plaatdikte</li>
                                            <li>Staal tot 6 mm</li>
                                            <li>RVS tot 6 mm</li>
                                            <li>Aluminium tot 8 mm</li>
                                            <li>In elke willekeurige hoek</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 px-4 d-flex align-items-center">
                                    <div className="img-colarge">
                                        <div className="colarge-1 mirr-cor">
                                            <img src={require('./../../images/colarge/IMG_5086_min.png').default} alt="" className="slide-righ" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default F_Start_Mirrored;