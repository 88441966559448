import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';

class Navigation extends React.Component {

    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/custom.js');

    };

    render() {
        return (
            <>
                <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
                    <ul className=" nav navbar-nav">
                        <li className='nav-link'><NavLink to={"/"}>Home</NavLink></li>
                        <li className='nav-link'><NavLink to={"/#productsr"}>Producten</NavLink></li>
                        <li className='nav-link'><NavLink to={"/#projectsr"}>Projecten</NavLink></li>
                        <li className='nav-link'><NavLink to={"/flensbeschermers"}>Flensbeschermers</NavLink></li>
                        <li className='nav-link'><NavLink to={"/plaatmateriaal"}>Plaatmateriaal</NavLink></li>
                        <li className='nav-link'><NavLink to={"/#aboutr"}>Over Ons</NavLink></li>
                        <li className='nav-link'><NavLink to={"/ContactUs"}>Contact</NavLink></li>
                    </ul>
                </div>
            </>
        );
    };
};

export default Navigation;