import React from 'react';
import { NavLink } from 'react-router-dom';

class F_Start extends React.Component {
    render() {
        return (
            <>
                <div className="section-full welcome-section-outer">
                    <div className="welcome-section-top bg-white p-t80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 px-4">
                                    <div className="img-colarge">
                                        <div className="colarge-1"><img src={require('./../../images/colarge/flensb1.png').default} alt="" className="slide-righ" /></div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center col-lg-6 col-md-12 px-4">
                                    <div className="welcom-to-section">
                                        {/* TITLE START*/}
                                        <div className="left wt-small-separator-outer">
                                            <div className="wt-small-separator site-text-primary">
                                                <div className="sep-leaf-left" />
                                                <div>Van Rietschoten</div>
                                                <div className="sep-leaf-right" />
                                            </div>
                                        </div>
                                        <h2>Flensbeschermers</h2>
                                        {/* TITLE END*/}
                                        <ul className="site-list-style-one">
                                            <li>Voorkomt Corrosie Onder Isolatie (C.U.I.)</li>
                                            <li>Speciaal ontworpen voor flensbescherming</li>
                                            <li>Reduceert onthoudskosten</li>
                                            <li>Verkrijgbaar met lekdetectiesysteem</li>
                                            <li>Herbruikbaar</li>
                                            <li>Vermindert brandgevaar</li>
                                            <li>Biedt persoonlijke bescherming</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default F_Start;