import React from 'react';
import { NavLink } from 'react-router-dom';

class F_Start_Mirrored extends React.Component {
    render() {
        return (
            <>
                <div className="section-full welcome-section-outer p-mir">
                    <div className="welcome-section-top bg-white">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 px-4 d-flex align-items-center">
                                    <div className="img-colarge">
                                        <div className="colarge-1">
                                            <img src={require('./../../images/colarge/IMG_5086_min.png').default} alt="" className="slide-righ" />
                                            <img src={require('./../../images/colarge/IMG_5072_min.png').default} alt="" className="extra-img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center col-lg-6 col-md-12 px-4 p-t40">
                                    <div className="welcom-to-section">
                                        <h2>Elektrisch hydraulische profielenwals</h2>
                                        <ul className="site-list-style-one">
                                            <li>Walsen van allerlei soorten materialen</li>
                                            <li>U-profielen</li>
                                            <li>T-ijzer</li>
                                        </ul>
                                        <h2 className="stack"><span>Waarom kiezen voor</span><span>Van Rietschoten?</span></h2>
                                        <ul className="site-list-style-one">
                                            <li>Op-maat knip- en zetwerk van plaatmateriaal</li>
                                            <li>Geavanceerd machinepark</li>
                                            <li>Up-to-date voorraad</li>
                                            <li>Levering alle staalsoorten en non-ferro materialen</li>
                                            <li>Vlakbij Europoort/Botlek</li>
                                            <li>Snelle levertijden, meestal binnen 24 uur</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default F_Start_Mirrored;