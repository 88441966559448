import React, { Suspense, useState, useEffect } from 'react';
import { productFormMapping, productPdfMapping } from './ProductFormMapping';

function ProductOrderForm({ handleOrderlistToggle, product }) {
    const [formData, setFormData] = useState({
        product_data: '',
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    useEffect(() => {
        if (product) {
            setFormData(prevState => ({ ...prevState, product_data: product.category + " - " + product.title }));
        }
    }, [product]);

    if (!product) return <p>No product selected</p>;
    const pdfFilename = productPdfMapping[`${product.category}_${product.title}`];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const FormComponent = productFormMapping[`${product.category}_${product.title}`];

    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log(formData);

        try {
            // const response = await fetch('./forms/quotation.php', {
            const response = await fetch('https://360fabriek.nl/rietschoten3/forms/quotation.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Success:', data);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    if (!FormComponent) return (
        <div className="whoops-not-found">
            <form className="cons-contact-form php-email-form"
                onSubmit={handleSubmit}
            // method="post"
            // role="form"
            >
                {/* TITLE START */}
                <div className="section-head left wt-small-separator-outer">
                    <div className="wt-small-separator site-text-primary">
                        <div className="sep-leaf-left" />
                        <div>Aanvraag</div>
                        <div className="sep-leaf-right" />
                    </div>
                    <h2>Vraag met dit formulier je offerte aan</h2>
                    <p>({product ? product.category + ' - ' + product.title : 'No product selected'})</p>
                </div>
                {/* TITLE END */}
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <input name="name" type="text" required className="form-control" placeholder="Naam" value={formData.name} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <input name="email" type="text" className="form-control" required placeholder="E-mail" value={formData.email} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                            <input name="phone" type="text" className="form-control" required placeholder="Telefoonnummer" value={formData.phone} onChange={handleChange} />
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <textarea name="message" className="form-control" rows={4} placeholder="Omschrijf hier je wensen en ontvang een offerte op maat!" value={formData.message} onChange={handleChange} />
                        </div>
                    </div>
                    <input type="hidden" name="product" value={product ? product.category + ' - ' + product.title : 'No product selected'} />
                    <div className="col-md-12">
                        <button type="submit" className="site-button site-btn-effect">Versturen</button>
                    </div>
                </div>
            </form>
        </div>
    );

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className="handvul">
                <p>Bestelformulier liever met de hand invullen?</p>
                <a class="site-button site-btn-effect" href={`./assets/downloads/${pdfFilename}`} download>Formulier downloaden</a>
            </div>
            <FormComponent product={product} handleOrderlistToggle={handleOrderlistToggle} />
        </Suspense>
    );
}

export default ProductOrderForm;
