import React from 'react';
import { NavLink } from 'react-router-dom';
import ProductOrderForm from './ProductOrderForm';

function OrderlistSegment({ isOrderlistActive, handleOrderlistToggle, product }) {

    return (
        <div className="orderlist-slide-hide bg-cover bg-no-repeat" style={{ right: isOrderlistActive ? '0%' : '-100%' }}>
            <div className="contact-nav">
                <span className="contact_close x-btn close-orderlist" id="xbtn" onClick={handleOrderlistToggle}><i className="fa">&#xf177;</i> Terug</span>
                <div className="contact-nav-form pt-0">
                    <div className="contact-nav-info bg-white bg-center bg-no-repeat assa orderlist-container">
                        <div className="row">
                            <div className="col">
                                <ProductOrderForm handleOrderlistToggle={handleOrderlistToggle} product={product} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderlistSegment;