import React from 'react';
import Header4 from '../Parts/Header4';
import Footer3 from './../Parts/Footer3';
import SliderSection from '../Segments/SliderSection';
import AboutUsSection from '../Segments/AboutUsSection';
import WhyUsSection from '../Segments/WhyUsSection';
import ProductsSection from './../Segments/ProductsSection';
import Testimonials7 from './../Segments/Testimonials7';
import ProjectsSection from '../Segments/ProjectsSection';

class Home1 extends React.Component {
    render() {
        return (
            <>
                <Header4 />
                <div className="page-content" id="main">
                    <SliderSection />
                    <div id='aboutr'><AboutUsSection /></div>
                    <WhyUsSection />
                    <div id='productsr'><ProductsSection /></div>
                    <div id='projectsr'><ProjectsSection /></div>
                    <Testimonials7 />
                </div>
                <Footer3 />
            </>
        );
    };
};

export default Home1;