import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const services = [
    {
        count: '01',
        flaticon: 'flaticon-oil',
        title: 'Voortdurendheid',
        description: 'Onze aanwezigheid in de branche sinds 1982 staat garant voor ervaring, betrouwbaarheid en consistentie in onze kwaliteitsbeloften. We zijn trots op onze geschiedenis en onze voortdurende inzet voor maatwerk.'
    },
    {
        count: '02',
        flaticon: 'flaticon-conveyor',
        title: 'Doen wat je belooft',
        description: 'Bij Van Rietschoten is ons woord ons gebod. We geloven in integriteit en zorgen ervoor dat we altijd onze beloftes nakomen, zowel in productkwaliteit als levertijden.'
    },
    {
        count: '03',
        flaticon: 'flaticon-robotic-arm',
        title: 'Korte Lijnen',
        description: 'Eenvoudige en directe communicatie is onze kracht. Met onze korte lijnen kunt u rekenen op transparante en snelle communicatie, wat uw vraag ook is.'
    },
    {
        count: '04',
        flaticon: 'flaticon-helmet',
        title: 'Snelle reactie op aanvragen',
        description: 'We begrijpen de urgentie van uw behoeften. Daarom reageren we altijd snel op aanvragen, zodat u niet op ons hoeft te wachten om verder te gaan met uw project.'
    },
    {
        count: '05',
        flaticon: 'flaticon-industry',
        title: 'Directe transporten',
        description: 'Wij verzenden uw producten rechtstreeks voor maximale efficiëntie en snelheid (niet via groupage). Onze directe transportoplossingen voorkomen vertragingen en zorgen ervoor dat uw bestellingen op tijd aankomen.'
    },
    {
        count: '06',
        flaticon: 'flaticon-oil',
        title: 'Meedenken in oplossingen',
        description: 'Bij Van Rietschoten werken we niet alleen voor u, maar ook met u. We denken proactief mee over oplossingen om uw unieke uitdagingen aan te gaan en uw doelen te bereiken.'
    },
    {
        count: '07',
        flaticon: 'flaticon-conveyor',
        title: 'Snelle levertijden',
        description: 'Door onze efficiënte processen en toegewijd team kunnen we onze producten uitzonderljik snel leveren. Vertrouw op ons voor snelle levertijden zonder in te boeten aan kwaliteit.'
    },
    {
        count: '08',
        flaticon: 'flaticon-robotic-arm',
        title: 'Alle constructies uit eigen werkplaats',
        description: 'Wij geloven in volledige controle over de kwaliteit. Daarom worden al onze constructies in eigen huis geproduceerd. Dit stelt ons in staat om de kwaliteit van onze producten op elk moment te waarborgen.'
    }
]

class WhyUsSection extends React.Component {
    render() {
        const options = {
            loop: true,
            autoplay: true,
            nav: true,
            dots: false,
            margin: 30,
            navText: ['<i class="fa fa-long-arrow-left"></i>', '<i class="fa fa-long-arrow-right"></i>'],
            responsive: {
                0: {
                    items: 1,
                },
                640: {
                    items: 1,
                },
                767: {
                    items: 2,
                },
                991: {
                    items: 2,
                },
                1366: {
                    items: 2,
                },
                1400: {
                    items: 3
                }
            }
        };
        return (
            <>
                <div className="section-full p-t80 p-b80 bg-gray-light">
                    <div className="container">
                        {/* TITLE START*/}
                        <div className="section-head center wt-small-separator-outer text-center">
                            <div className="wt-small-separator site-text-primary">
                                <div className="sep-leaf-left" />
                                <div>Waarom Van Rietschoten?</div>
                                <div className="sep-leaf-right" />
                            </div>
                            <h2>Wat Je Van Ons Kan Verwachten</h2>
                        </div>
                        {/* TITLE END*/}
                        <div className="section-content">
                            <OwlCarousel className="owl-carousel services-style-new owl-btn-vertical-center" {...options}>
                                {services.map((item, index) => (
                                    <div key={index} className="item">
                                        <div className="wt-box service-box-1-new bg-white">
                                            <div className="wt-icon-box-wraper-new">
                                                <div className="icon-xl inline-icon">
                                                    <span className="icon-cell site-text-primary"><i className={item.flaticon} /></span>
                                                </div>
                                                <div className="wt-icon-number-new"><span>{item.count}</span></div>
                                            </div>
                                            <div className="service-box-title-new title-style-2 site-text-secondry">
                                                <span className="s-title-one">{item.title}</span>
                                            </div>
                                            <div className="service-box-content-new">
                                                <p>{item.description}</p>
                                            </div>
                                            {/*  READ MORE SECTION
                                            <div className="service-box-new-link">
                                                <NavLink to={"/services-mechanical"} className="site-button-link">Read More</NavLink>
                                            </div> */}
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default WhyUsSection;