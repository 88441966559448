import React from 'react';
import Header from '../Parts/Header';
import Footer3 from '../Parts/Footer3';
import Banner from './../Segments/Banner';
import F_Start from './../Segments/F_Start';
import F_Start_Mirrored from './../Segments/F_Start_Mirrored';
import F_Mid from './../Segments/F_Mid';
import Projects12 from './../Segments/Projects12';
import Statistics12 from './../Segments/Statistics12';
import Video2 from './../Segments/Video2';
import SelfIntro1 from './../Segments/SelfIntro1';
import ClientsLogo1 from './../Segments/ClientsLogo1';

var bnrimg = require('./../../images/background/bg-map2.png');
var flensbeschermers_full = require('./../../images/main-slider/slider1/flensb3.png');
var flensbeschermers_full_2 = require('./../../images/main-slider/slider1/flensb7.png');

class About2 extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Banner extraclass="flensbeschermersbanner" title="Flensbeschermers" pagename="Flensbeschermers" bgimage={bnrimg.default} />
                    <F_Start />
                    <F_Start_Mirrored />
                    <Banner extraclass="flensbeschermers_full" bgimage={flensbeschermers_full.default} />
                    <F_Mid />
                    <Banner extraclass="flensbeschermers_full" bgimage={flensbeschermers_full_2.default} />
                    <ul className="site-list-style-one p-tb80 customUL">
                        <li>Voorkomt Corrosie Onder Isolatie (C.U.I.)</li>
                        <li>Speciaal ontworpen voor flensbescherming</li>
                        <li>Reduceert onthoudskosten</li>
                        <li>Verkrijgbaar met lekdetectiesysteem</li>
                        <li>Herbruikbaar</li>
                        <li>Vermindert brandgevaar</li>
                        <li>Biedt persoonlijke bescherming</li>
                    </ul>
                </div>
                <Footer3 />
            </>
        );
    };
};

export default About2;