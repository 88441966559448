import React from 'react';
import { NavLink } from 'react-router-dom';

class AboutUsSection extends React.Component {
    render() {
        return (
            <>
                <div className="section-full welcome-section-outer" id="about">
                    <div className="welcome-section-top bg-white p-tb80">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-12">
                                    <div className="img-colarge-new">
                                        <div className="colarge-1-new"><img src={require('./../../images/new-index/colarge/DSC01274.png').default} alt="" className="slide-righ" /></div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <div className="welcom-to-section-new">
                                        {/* TITLE START*/}
                                        <div className="left wt-small-separator-outer">
                                            <div className="wt-small-separator site-text-primary">
                                                <div className="sep-leaf-left" />
                                                <div>Van Rietschoten</div>
                                                <div className="sep-leaf-right" />
                                            </div>
                                        </div>
                                        <h2 className="balance">Thermisch-Ontkoppelde Constructies Van De Hoogste Kwaliteit</h2>
                                        {/* TITLE END*/}
                                        <ul className="site-list-style-one-new">
                                            <li>IJzersterk maatwerk</li>
                                            <li>Snelle levering</li>
                                            <li>Uitzonderlijke Precisie</li>
                                        </ul>
                                        <p>Van Rietschoten is uw toonaangevende expert in de ontwikkeling en vervaardiging van thermisch-ontkoppelde constructies. Als prominente speler in specialistisch constructiewerk bieden we bij Van Rietschoten hoogwaardige, op maat gemaakte constructies. Vanuit onze oorsprong als constructiebedrijf zijn we uitgegroeid tot een allround leverancier van robuuste, aangepaste constructies. Onze omvangrijke voorraad materialen garandeert een snelle levering. Met specifieke expertise vertrouwen we op onze precisie-machines voor optimale resultaten.</p>
                                        <div className="welcom-to-section-bottom d-flex justify-content-between">
                                            <div className="welcom-btn-position"><a href="#projectsr" className="site-button-secondry site-btn-effect">Projecten</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutUsSection;