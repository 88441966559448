import React from 'react';
import { NavLink } from 'react-router-dom';

const filters = [
    { label: "Tank 21060 (Loders Croklaan, Maasvlakte)", filter: ".cat-1" },
    // { label: "Ecosystem", filter: ".cat-2" },
];

const projects = [
    {
        image: require('./../../images/projects/tank21060/2dcc9b9a-22bd-41dc-ac7a-9e21b0fb5790.jpg'),
        title: 'Tank 21060',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/projects/tank21060/e968f30c-fe30-44c2-92d8-61e042a29258.jpg'),
        title: 'Tank 21060',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/projects/tank21060/a0ee5fba-ca7c-4a2b-be96-e795512d338a.jpg'),
        title: 'Tank 21060',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/projects/tank21060/20230404_165505.jpg'),
        title: 'Tank 21060',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/projects/tank21060/cf6d1f3e-7fee-480e-ac0d-68db33940f69.JPG'),
        title: 'Tank 21060',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/projects/tank21060/02661183-0d8d-4542-b0a8-b09e65ae50b8.JPG'),
        title: 'Tank 21060',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/projects/tank21060/25c0e81c-dea6-4432-8b35-6b471f59c065.JPG'),
        title: 'Tank 21060',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/projects/tank21060/PHOTO-2021-02-24-10-39-1717.jpg'),
        title: 'Tank 21060',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/projects/tank21060/77fb0d2f-076f-4a7b-8eef-7f914fdae155.JPG'),
        title: 'Tank 21060',
        filter: 'cat-1'
    },
];

var bnr1 = require('./../../images/solar-icon/bg/1.png');

class ProjectsSection extends React.Component {

    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50  bg-no-repeat bg-bottom-right" style={{ backgroundColor: '#dff6fc' }}>
                    <div className="container">
                        {/* TITLE START*/}
                        <div className="section-head center wt-small-separator-outer text-center">
                            <div className="wt-small-separator site-text-primary">
                                <div className="sep-leaf-left" />
                                <div>Ons Werk</div>
                                <div className="sep-leaf-right" />
                            </div>
                            <h2>Voorbeelden Van Recent Afgeleverd Werk</h2>
                        </div>
                        {/* TITLE END*/}
                        {/* PAGINATION START */}
                        <div className="filter-wrap work-grid p-b30 text-center">
                            <ul className="masonry-filter link-style ">
                                <li className="active"><NavLink to={"#"} data-filter="*">Alles</NavLink></li>
                                {filters.map((item, index) => (
                                    <li key={index}><NavLink to={"#"} data-filter={item.filter}>{item.label}</NavLink></li>
                                ))}
                            </ul>
                        </div>
                        {/* PAGINATION END */}
                        {/* GALLERY CONTENT START */}
                        <div className="masonry-wrap mfp-gallery  row clearfix d-flex justify-content-center flex-wrap">
                            {projects.map((item, index) => (
                                <div key={index} className={`${item.filter} masonry-item col-lg-4 col-md-6 col-sm-6 m-b30`}>
                                    <div className="project-img-effect-1">
                                        <img src={item.image.default} alt="" />
                                        <div className="wt-info">
                                            <h3 className="wt-tilte m-b0 m-t0"><NavLink to={"/project-detail"}>{item.title}</NavLink></h3>
                                        </div>
                                        <a href={item.image.default} className="mfp-link"><i className="fa fa-search-plus" /></a>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* GALLERY CONTENT END */}
                    </div>
                </div>
            </>
        );
    }
};

export default ProjectsSection;