import React from 'react';
import { NavLink } from 'react-router-dom';

class F_Start_Mirrored extends React.Component {
    render() {
        return (
            <>
                <div className="section-full welcome-section-outer">
                    <div className="welcome-section-top bg-white p-tb80">
                        <div className="container">
                            <div className="row">
                                <div className="d-flex align-items-center col-lg-6 col-md-12 px-4">
                                    <div className="welcom-to-section">
                                        <ul className="site-list-style-one">
                                            <li>Een makkelijke manier om flenzen te beschermen</li>
                                            <li>Simpel te monteren</li>
                                            <li>Meerdere malen te gebruiken</li>
                                            <li>Het voorkomen van brand</li>
                                            <li>Het kunnen ontdekken van lekkende, geïsoleerde flenzen d.m.v. het gebruik van lekpijpjes</li>
                                            <li>Leverbaar in alle diameters</li>
                                            <li>Lekpijpjes leverbaar in alle gewenste lengtes</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 px-4">
                                    <div className="img-colarge flensbeschermers-single">
                                        <div className="colarge-1 mirr-cor"><img src={require('./../../images/colarge/flensb2.png').default} alt="" className="slide-righ" /></div>
                                        <div className="since-year-outer"><div className="since-year"><span>Van</span><strong>Rietschoten</strong></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default F_Start_Mirrored;